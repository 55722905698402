<template>
  <b-container>
    <div>
      <b-row><b-col><h1>Gestión de Usuarios</h1></b-col></b-row>
    </div>
    <div v-if="autorizado === true">
      <b-tabs content-class="mt-3" class="tabGramos" justified>
        <b-tab title="Datos personales" active>
          <b-row>
            <b-col lg="12">
              <div v-if="users">
                <b-row>
                  <b-col lg="6">
                    <download-excel
                        class="btn btn-black"
                        :data="excel_datospersonales_data"
                        :fields="excel_datospersonales_fields"
                        worksheet="My Worksheet"
                        name="Gestión de Usuarios - Datos personales.xls"
                    >
                      <b-button class="text-uppercase rojoGramos">Descargar <b-icon-download></b-icon-download></b-button>
                    </download-excel>
                  </b-col>
                  <b-col lg="6">
                    <b-row>
                      <b-col lg="6" offset-lg="6">
                        <div class="d-flex flex-row justify-content-end mb-2">
                          <div class="px-2"><b-select v-model="selectedTipoDatosPersonales" :options="optionsTipoDatosPersonales"></b-select></div>
                          <div class="ps-2"><b-button class="rojoGramos" v-on:click="loadDatosPersonales()"> <b-icon icon="arrow-clockwise" :animation="animationDatosPersonales" font-scale="1"></b-icon></b-button></div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="4" offset-lg="8" class="my-1">
                    <b-form-group
                        label="Filtrar"
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-input
                            id="filter-input"
                            v-model="filterDatosPersonales"
                            type="search"
                            placeholder="Buscar en esta sección"
                        ></b-form-input>

                        <b-input-group-append>
                          <b-button class="rojoGramos" :disabled="!filterDatosPersonales" @click="filterDatosPersonales = ''">Limpiar</b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-card>
                  <b-table
                      :items="items_datospersonales"
                      :fields="fields_datospersonales"
                      :sort-by.sync="sortByDatosPersonales"
                      :sort-desc.sync="sortDescDatosPersonales"
                      :filter="filterDatosPersonales"
                      :filter-included-fields="filterOn"
                      sort-icon-left
                  >
                    <template #cell(fullName)="row">
                      <span class="mainValue"><a v-bind:href="'/gestion_usuarios/usuarios/'+ row.item.user_id">{{ row.item.fullName }}</a></span>
                    </template>
                  </b-table>
                </b-card>
              </div>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="KPIs">
          <b-row>
            <b-col lg="12">
              <div v-if="kpis">
                <b-row>
                  <b-col>
                    <download-excel
                        class="btn btn-black"
                        :data="excel_kpis_data"
                        :fields="excel_kpis_fields"
                        worksheet="My Worksheet"
                        name="Gestión de Usuarios - KPIs.xls"
                    >
                      <b-button class="text-uppercase rojoGramos">Descargar <b-icon-download></b-icon-download></b-button>
                    </download-excel>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="4" offset-lg="8" class="my-1">
                    <b-form-group
                        label="Filtrar"
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-input
                            id="filter-input"
                            v-model="filterKPIs"
                            type="search"
                            placeholder="Buscar en esta sección"
                        ></b-form-input>

                        <b-input-group-append>
                          <b-button class="rojoGramos" :disabled="!filterKPIs" @click="filterKPIs = ''">Limpiar</b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-card>
                  <b-table
                      :items="items_kpis"
                      :fields="fields_kpis"
                      :filter="filterKPIs"


                      sort-icon-left>
                    <template #cell(fullName)="row">
                      <span class="mainValue"><a v-bind:href="'/gestion_usuarios/usuarios/'+ row.item.user_id">{{ row.item.fullName }}</a></span>
                    </template>

                  </b-table>
                </b-card>
              </div>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Pagos">
          <b-row>
            <b-col lg="12">
              <div v-if="payments">
                <b-row>
                  <b-col lg="6">
                    <download-excel
                        class="btn btn-black"
                        :data="excel_payments_data"
                        :fields="excel_payments_fields"
                        worksheet="My Worksheet"
                        name="Gestión de Usuarios - Pagos.xls"
                    >
                      <b-button class="text-uppercase rojoGramos">Descargar <b-icon-download></b-icon-download></b-button>
                    </download-excel>
                  </b-col>
                  <b-col lg="6">
                    <div class="d-flex flex-row justify-content-end mb-2">
                      <div class="px-2"><b-select v-model="selectedPlanDePago" :options="optionsPlanDePago"></b-select></div>
                      <div class="px-2"><b-select v-model="selectedEstadoDePago" :options="optionsEstadoDePago"></b-select></div>
                      <div class="ps-2"><b-button class="rojoGramos" v-on:click="loadPayments()"> <b-icon icon="arrow-clockwise" :animation="animationPayments" font-scale="1"></b-icon></b-button></div>
                    </div>
                  </b-col>
                </b-row>
                <b-row>

<!--                  <b-col lg="4">-->
<!--                    <b-row>-->
<!--                      <b-col lg="3">-->
<!--                        <b-form-group-->
<!--                            label="Fecha desde"-->
<!--                            label-for="filter-input"-->
<!--                            label-cols-sm="3"-->
<!--                            label-align-sm="right"-->
<!--                            label-size="sm"-->
<!--                            class="mb-0"-->
<!--                        >-->
<!--                          <b-input-group size="sm">-->
<!--                            <b-form-input-->
<!--                                id="filter-input"-->
<!--                                v-model="promociones_suscripciones_desde"-->
<!--                                type="search"-->
<!--                                placeholder="Fecha desde"-->
<!--                            ></b-form-input>-->
<!--                          </b-input-group>-->
<!--                        </b-form-group>-->
<!--                      </b-col>-->
<!--                      <b-col lg="3">-->
<!--                        <b-form-group-->
<!--                            label="Fecha hasta"-->
<!--                            label-for="filter-input"-->
<!--                            label-cols-sm="3"-->
<!--                            label-align-sm="right"-->
<!--                            label-size="sm"-->
<!--                            class="mb-0"-->
<!--                        >-->
<!--                          <b-input-group size="sm">-->
<!--                            <b-form-input-->
<!--                                id="filter-input"-->
<!--                                v-model="promociones_suscripciones_hasta"-->
<!--                                type="search"-->
<!--                                placeholder="Fecha hasta"-->
<!--                            ></b-form-input>-->
<!--                          </b-input-group>-->
<!--                        </b-form-group>-->
<!--                      </b-col>-->
<!--                      <b-col lg="3">-->
<!--                        <b-input-group-append>-->
<!--                          <b-button class="rojoGramos" @click="actualizarPromocionesSuscripciones">Actualizar</b-button>-->
<!--                        </b-input-group-append>-->

<!--                      </b-col>-->
<!--                    </b-row>-->

<!--                  </b-col>-->

                  <b-col lg="4" offset-lg="8" class="my-1">
                    <b-form-group
                        label="Filtrar"
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-input
                            id="filter-input"
                            v-model="filterPagos"
                            type="search"
                            placeholder="Buscar en esta sección"
                        ></b-form-input>

                        <b-input-group-append>
                          <b-button class="rojoGramos" :disabled="!filterPagos" @click="filterPagos = ''">Limpiar</b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-card>
                  <b-table
                      :items="items_payments"
                      :fields="fields_payments"
                      :filter="filterPagos"


                      sort-icon-left>
                    <template #cell(fullName)="row">
                      <span class="mainValue"><a v-bind:href="'/gestion_usuarios/usuarios/'+ row.item.user_id">{{ row.item.fullName }}</a></span>
                    </template>
                    <template #cell(paymentHistory)="row">
                      <div v-if="row.item.paymentHistory.length > 0">
                        {{ row.item.paymentHistory[0].date | moment("DD/MM/YYYY") }} - {{ row.item.paymentHistory[0].amount }}
                        <b-button class="buttonHistorial" v-b-modal.payment @click="dataPayment(row.item.user_id)" v-bind:title="'Ver historial de pagos '+row.item.fullName"><b-icon-chevron-down></b-icon-chevron-down></b-button>
                      </div>
                    </template>

                  </b-table>
                  <b-modal id="payment" title="Historial de pagos">
                    <div v-if="payment_history.length > 0">
                      <b-table-simple>
                        <b-thead>
                          <b-tr>
                            <b-td>Fecha</b-td>
                            <b-td>Estado</b-td>
                            <b-td>Monto</b-td>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr v-for="logPayment in payment_history" v-bind:key="logPayment">
                            <b-td>{{ logPayment.date | moment("DD/MM/YYYY")  }}</b-td>
                            <b-td>{{ logPayment.status }}</b-td>
                            <b-td>$ {{ logPayment.amount }}</b-td>
                          </b-tr>
                        </b-tbody>

                      </b-table-simple>
                    </div>
                  </b-modal>
                </b-card>
              </div>
            </b-col>
          </b-row>
        </b-tab>

      </b-tabs>
    </div>
    <div v-else-if="autorizado === false">
      <b-row><b-col><p><strong>Acceso no autorizado. Debe <a v-bind:href="'/'">iniciar sesión</a>. </strong></p></b-col></b-row>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";
import auth from "../logic/auth";
import moment from "moment";
export default {
  data() {
    return {
      autorizado: null,
      users: [],
      kpis: [],
      payments: [],
      payment_history: [],
      promociones_suscripciones_desde: null,
      promociones_suscripciones_hasta: null,

      filterDatosPersonales: null,
      filterKPIs: null,
      filterPagos: null,
      filterOn: [],

      sortByDatosPersonales: 'fullName',
      sortDescDatosPersonales: false,
      fields_datospersonales: [
        {
          key: 'fullName',
          label: 'Usuario',
          sortable: true,
        },
        {
          key: 'email',
          label: 'E-mail',
          sortable: true
        },
        {
          key: 'type',
          label: 'Tipo',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          formatter: (value) => {
            if (value === 'N')
              return "Nuevo"
            else if (value === 'A')
              return "Activo"
            else if (value === 'I')
              return "Inactivo"
            else if (value === 'P')
              return "Perdido"
            else if (value === 'G')
              return "Graduado"
            else if (value === 'BG')
              return "Baja Graduado"
            else if (value === 'BI')
              return "Baja Incompleto"
          }
        },
        {
          key: 'birth',
          label: 'Fecha nac',
          sortable: true,
          formatter: (value/*, key, item*/) => {
            return moment(value).format('DD/MM/YYYY');
          },
        },
        {
          key: 'gender',
          label: 'Sexo',
          sortable: true
        },
        {
          key: 'height',
          label: 'Estatura (cm)',
          sortable: true
        },
        {
          key: 'weight',
          label: 'Peso (kg)',
          sortable: true
        },
        {
          key: 'bmi',
          label: 'BMI',
          sortable: true,
          formatter: (value/*, key, item*/) => {
            return value?value.toString().replace('.', ','):''
          },
        },
        {
          key: 'country',
          label: 'País',
          sortable: true
        }
      ],

      items_datospersonales: [],

      excel_datospersonales_fields: {
        Usuario: "fullName",
        "e-mail": "email",
        Tipo: {
          field: "type",
          callback: (value) => {
            if (value === 'N')
              return "Nuevo"
            else if (value === 'A')
              return "Activo"
            else if (value === 'I')
              return "Inactivo"
            else if (value === 'P')
              return "Perdido"
            else if (value === 'G')
              return "Graduado"
            else if (value === 'BG')
              return "Baja Graduado"
            else if (value === 'BI')
              return "Baja Incompleto"
          }
        },
        "Fecha nacimiento": {
          field: "birth",
          callback: (value) => {
            return moment(value).format('DD/MM/YYYY');
          },
        },
        Sexo: "gender",
        "Estatura (cm)": "height",
        "Peso (kg)": "weight",
        BMI: {
          field: "bmi",
          callback: (value) => {
            return value.toString().replace('.', ',')
          }
        },
        "País": "country"
      },

      excel_datospersonales_data: [],
      excel_datospersonales_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],

      selectedTipoDatosPersonales: '',
      optionsTipoDatosPersonales: [],
      animationDatosPersonales: '',

      sortByKPIs: 'fullName',
      sortDescKPIs: false,
      fields_kpis: [
        {
          key: 'fullName',
          label: 'Nombre y apellido',
          sortable: true,
        },
        {
          key: 'avgProgress',
          label: 'Progreso (%)',
          sortable: true,
          formatter: (value/*, key, item*/) => {
            if (value)
              return value.toString().replace('.', ',')
          },
        },
        {
          key: 'lossWeight',
          label: 'Kg perdidos',
          sortable: true,
          formatter: (value/*, key, item*/) => {
            if (value)
              return value.toFixed(1).replace('.', ',')
          },
        },
        {
          key: 'avgDataCompleted',
          label: 'Carga datos (%)',
          sortable: true,
          formatter: (value/*, key, item*/) => {
            return value.toString().replace('.', ',')
          },

        },
        {
          key: 'avgListenedAudios',
          label: 'Escucha audios (%)',
          sortable: true,
          formatter: (value/*, key, item*/) => {
            return value.toString().replace('.', ',')
          },
        },
        {
          key: 'totalWatchedVideos',
          label: 'Visualiz. videos (min)',
          sortable: true
        },
        {
          key: 'timeInGramos',
          label: 'Tiempo en programa (días)',
          sortable: true
        },
      ],

      items_kpis: [],

      excel_kpis_fields: {
        Usuario: "fullName",
        "Progreso (%)": {
          field: "avgProgress",
          callback: (value) =>{
            if (value)
              return parseFloat(value).toFixed(2).replace('.', ',')
          }
        },
        "Kg. perdidos": {
          field: "lossWeight",
          callback: (value) => {
            if (value)
              return parseFloat(value).toFixed(2).replace('.', ',')
          }
        },
        "Carga datos (%)": {
          field: "avgDataCompleted",
          callback: (value) => {
            if (value)
              return parseFloat(value).toFixed(2).replace('.', ',')
          }
        },
        "Escucha audios (%)": {
          field: "avgListenedAudios",
          callback: (value) => {
            if (value)
              return parseFloat(value).toFixed(2).replace('.', ',')
          }
        },
        "Visualiz. videos (min)": "totalWatchedVideos",
        "Tiempo en programa (días)": "timeInGramos",
      },

      excel_kpis_data: [],
      excel_kpis_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],


      sortByPaymentss: 'fullName',
      sortDescPaymentss: false,
      fields_payments: [
        {
          key: 'fullName',
          label: 'Nombre y apellido',
          sortable: true,
        },
        {
          key: 'subscription',
          label: 'Plan de pago',
          sortable: true,
          formatter: (value/*, key, item*/) => {
            if (value)
              return value.replace('suscripción ', '').replace('Suscripción ', '')
          },
        },
        {
          key: 'paymentStatus',
          label: 'Estado de pago',
          sortable: true,
        },
        {
          key: 'amount',
          label: 'Monto a pagar',
          sortable: true,
          formatter: (value/*, key, item*/) => {
            if (value) return '$ ' + value
          },

        },
        {
          key: 'paymentDueDate',
          label: 'Fecha próximo pago',
          sortable: true,
          formatter: (value/*, key, item*/) => {
            if (value) return moment(value).format('DD/MM/YYYY');
          },
        },
        {
          key: 'paymentHistory',
          label: 'Historial de pago',
          sortable: true
        },

      ],

      items_payments: [],

      excel_payments_fields: {
        Usuario: "fullName",
        "Plan de Pago": "subscription",
        "Estado de Pago": "paymentStatus",
        "Monto a pagar": "amount",
        "Fecha próximo pago": {
          field: "paymentDueDate",
          callback: (value) => {
            if (value){
              return moment(value).format('DD/MM/YYYY')
            }
          }
        },
      },

      excel_payments_data: [],
      excel_payments_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],

      selectedPlanDePago: null,
      optionsPlanDePago: [],
      selectedEstadoDePago: null,
      optionsEstadoDePago: [
        {value: null, text: "Todos los estados de pago"},
        {value: "PAD", text: "Pago al día"},
        {value: "PVEM", text: "Pago por vencer este mes"},
        {value: "PV", text: "Pago vencido"},
        {value: "PR", text: "Pago rechazado"},
      ],
      animationPayments: '',

    }
  },
  methods:{
    dataPayment: function(user_id){
      console.log(user_id)
      axios.get(process.env.VUE_APP_GRAMOSAPIHOST + 'admin/users/paymentsHistory?user_id='+user_id,
          {
            headers: {
              'Authorization': 'Bearer ' + this.accessToken
            }
          })
          .then(response => {
            this.autorizado = true
            this.payment_history = response.data.paymentHistory
          })
          .catch(err => {
            this.autorizado = false
            console.log(err);
          });
    },
    loadDatosPersonales: function(){
      this.animationDatosPersonales = 'spin'
      // let url = process.env.VUE_APP_GRAMOSAPIHOST+'admin/contact?startDate='+this.selectedDesdePerformance+'&endDate='+this.selectedHastaPerformance+(this.selectedTipoPerformance?'&type='+this.selectedTipoPerformance:'')
      axios
          .get(process.env.VUE_APP_GRAMOSAPIHOST + 'admin/users/info'+(this.selectedTipoDatosPersonales?'?type='+this.selectedTipoDatosPersonales:''),
              {
                headers: {
                  'Authorization': 'Bearer ' + this.accessToken
                }
              })
          .then(response => {
            this.autorizado = true
            this.users = response.data
            this.items_datospersonales = response.data
            this.excel_datospersonales_data = response.data
          })
          .catch(err => {
            this.autorizado = false
            console.log(err);
          })
      setTimeout(() => this.animationDatosPersonales = '', 2000);
    },
    loadPayments: function(){
      this.animationPayments = 'spin'
      let paramsUrl = null
      if (this.selectedEstadoDePago){
        paramsUrl = '?type='+this.selectedEstadoDePago
      }
      if (this.selectedPlanDePago){
        if (paramsUrl){
          paramsUrl += '&'
        }
        else{
          paramsUrl = '?'
        }
        paramsUrl += 'plan='+this.selectedPlanDePago
      }
      axios
          .get(process.env.VUE_APP_GRAMOSAPIHOST + 'admin/users/payments'+(paramsUrl?paramsUrl:''),
              {
                headers: {
                  'Authorization': 'Bearer ' + this.accessToken
                }
              })
          .then(response => {
            this.autorizado = true
            this.payments = response.data
            this.items_payments = response.data
            this.excel_payments_data = response.data
          })
          .catch(err => {
            this.autorizado = false
            console.log(err);
          });
      setTimeout(() => this.animationPayments = '', 2000);
      axios
          .get(process.env.VUE_APP_GRAMOSAPIHOST + 'admin/suscripciones_promociones',
              {
                headers: {
                  'Authorization': 'Bearer ' + this.accessToken
                }
              })
          .then(response => {
            this.autorizado = true
            console.log(response)
            this.promociones_suscripciones_desde = response.data.day_begin
            this.promociones_suscripciones_hasta = response.data.day_end
            console.log(this.promociones_suscripciones_desde)
            console.log(this.promociones_suscripciones_hasta)
          })
          .catch(err => {
            this.autorizado = false
            console.log(err);
          });
    },
    actualizarPromocionesSuscripciones: function(){
      console.log(this.promociones_suscripciones_desde)
      console.log(this.promociones_suscripciones_hasta)
      //TODO agregar alet desde mayor a hasta
      axios
          .post(process.env.VUE_APP_GRAMOSAPIHOST + 'admin/suscripciones_promociones',
              {day_begin: this.promociones_suscripciones_desde, day_end: this.promociones_suscripciones_hasta},
              {
                headers: {
                  'Authorization': 'Bearer ' + this.accessToken
                }
              })
          .then(() => {
            this.autorizado = true
            //console.log(response)
          })
          .catch(err => {
            this.autorizado = false
            console.log(err);
          });

    }
  },
  mounted() {
    this.accessToken = auth.getUserLogged('accessToken')
    if (this.accessToken) {

      axios
          .get(process.env.VUE_APP_GRAMOSAPIHOST+'admin/dataFilter', {
            headers: {
              'Authorization': 'Bearer ' + this.accessToken
            }
          })
          .then(response =>{

            this.selectedTipoDatosPersonales = response.data.filterProfilesTypes.selected
            this.optionsTipoDatosPersonales = response.data.filterProfilesTypes.options
            this.optionsPlanDePago = response.data.filterSubscriptions.options
          })

      this.loadDatosPersonales()


      axios
          .get(process.env.VUE_APP_GRAMOSAPIHOST + 'admin/users/kpis',
              {
                headers: {
                  'Authorization': 'Bearer ' + this.accessToken
                }
              })
          .then(response => {
            this.autorizado = true
            this.kpis = response.data
            this.items_kpis = response.data
            this.excel_kpis_data = response.data
          })
          .catch(err => {
            this.autorizado = false
            console.log(err);
          });

      this.loadPayments()


    }
    else{
        this.autorizado = false
    }
  }
};
</script>